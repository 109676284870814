.App {
    text-align: center;
    color: #333; 
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.react-datepicker-wrapper {
   
}

.react-datepicker-wrapper input {
    font-size: 2em;
    max-width: 7em;
    
}

.form-group {

    margin-bottom: 0;

}

.tui-full-calendar-layout {
    border: 1px solid lightgrey;
}

.tui-full-calendar-dayname-container {
    overflow-y: auto;
}

.btn {
    border-radius: 2px;
    border: none;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.btn-sm {
    padding: 4px 8px;
}

.btn-primary {
    background-color: #89d700;
    color: #252525;
}

.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
    background-color: #7cc202;
    color: #252525;

}

.btn-secondary {
    background-color: #444444;
    color: #ffffff;

}

.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
    background-color:#555555;
    color: #ffffff;

}

.alert-success {
    background-color: rgba(137,215,0,0.15);

}


.rbc-event, .rbc-selected {
    background-color: #89d700 !important;
    color: #252525;
}

.toast {
    font-size: 20px !important;
    max-width: 750px !important;
}

.planner-select, .alert-planner {
    font-size: 14px !important;
}

.rbc-row-content {
    z-index: -1 !important;
}